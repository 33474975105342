<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onSubmit)"
      @close="onClose"
      submit="Save"
      :loading="isLoading"
      :disabled-button="isLoading"
      button-class="bg-dynamicBackBtn text-white"
      >
      <template v-slot:title>
        <h1 class="text-darkPurple font-bold text-lg leading-5 p-0">
          New Pay Grade
        </h1>
      </template>
      <template>
        <div class="flex flex-col justify-start items-start gap-3">
          <div class="w-full">
            <TextInput
              class="w-full text-sm font-normal leading-5 text-darkPurple"
              v-model="payload.payGradeId"
              label="Pay Grade Name"
              placeholder="Pay Grade Name"
              :rules="['required']"
            />
          </div>
          <div class="w-full">
            <label class="date-label">Location</label>
            <v-select
              :clearable="false"
              class="style-select"
              multiple
              :rules="['required']"
              :reduce="option => option.id"
              label="name"
              v-model="payload.locationIds"
              :options="locationOptions"
              :close-on-select="true"
            >
              <template #option="{name }">
                <div class="flex">
                  <div class="hover:text-white">
                    <div class="font-bold mt-1 text-sm cursor-pointer">
                      {{ name }}
                    </div>
                  </div>
                </div>
              </template>
              <template #selected-option="{name}">
                {{ name }}
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon
                    icon-name="caret-down"
                    style="margin-right:14px"
                    size="xsm"
                  />
                </span>
              </template>
            </v-select>
          </div>
          <div class="w-full">
            <Select
              class="w-full text-sm font-normal leading-5 text-darkPurple"
              placeholder="--Select--"
              :options="levelOptions"
              v-model="payload.levelId"
              :value="4"
              :rules="['required']"
              label="Job Level"
              @input="onReferenceMarketRate"
            />
          </div>
          <div class="w-full">
            <Select
              class="w-full text-sm font-normal leading-5 text-darkPurple"
              v-model="payload.functionIds[0]"
              placeholder="--Select--"
              :options="funtionOptions"
              :rules="['required']"
              label="Function"
            />
          </div>
          <div class="w-full">
            <Select
              class="w-full text-sm font-normal leading-5 text-darkPurple"
              v-model="payload.designationIds[0]"
              placeholder="--Select--"
              :options="designationOptions"
              :rules="['required']"
              label="Designation"
            />
            <div  v-if="!designationOptions.length && payload.levelId && payload.functionIds">
              <p class="text-flame text-xs text-bold">
                * There are no designations for the selected Level / Function
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-start items-start w-full">
            <TextInput
              v-if="payload.referenceMarketRate"
              type="number"
              class="w-full text-sm font-normal leading-5 text-darkPurple"
              placeholder="Enter"
              v-model.number="payload.currentMarketRate"
              label="Current Market Rate"
              @input="onReferenceMarketRate"
            />
            <TextInput
              v-else
              min="0"
              type="number"
              class="w-full text-sm font-normal leading-5 text-darkPurple"
              placeholder="Amount"
              :rules="['required']"
              v-model.number="payload.midPoint"
              label="Annual Range Midpoint (₦)"
              @input="onReferenceMarketRate"
            />
          </div>
          <div class="w-full" v-if="payload.referenceMarketRate">
            <TextInput
              type="number"
              class="w-full text-sm font-normal leading-5 text-darkPurple"
              placeholder="Enter"
              v-model.number="payload.policyPercent"
              label="Policy Percentage (%)"
              @input="onReferenceMarketRate"
            />
          </div>
          <template v-if="payload.referenceMarketRate">
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-sm text-jet">Target Percentile (%)</p>
              <div class="payInfo payInfo_fakeInput text-center text-darkPurple">
                <template v-if="!isFetchingPayGrade">
                  <span v-if="payload.targetPercentile">
                    {{ payload.targetPercentile }}%
                  </span>
                  <span v-else>Autocalculated</span>
                </template>
                <span v-else>Calculating....</span>
              </div>
            </div>
          </template>
          <template v-if="payload.referenceMarketRate">
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-sm text-jet">Annual Range Midpoint (₦)</p>
              <div class="payInfo payInfo_fakeInput text-center text-darkPurple">
                <template v-if="!isFetchingPayGrade">
                  <span v-if="payload.rangeMidPoint">
                    {{ convertToCurrency(payload.rangeMidPoint) }}
                  </span>
                  <span v-else>Autocalculated</span>
                </template>
                <span v-else>Calculating....</span>
              </div>
            </div>
          </template>
          <template>
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-sm text-jet">Range Spread</p>
              <div class="payInfo payInfo_fakeInput text-center text-darkPurple">
                <template v-if="!isFetchingPayGrade">
                  <span v-if="payload.rangeSpread">
                    {{ payload.rangeSpread }}
                  </span>
                  <span v-else>Autocalculated</span>
                </template>
                <span v-else>Calculating....</span>
              </div>
            </div>
          </template>
          <template>
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-sm text-jet">Annual Range Minimum (₦)</p>
              <div class="payInfo payInfo_fakeInput text-center text-darkPurple">
                <template v-if="!isFetchingPayGrade">
                  <span v-if="payload.minimumrange">
                    {{ convertToCurrency(payload.minimumrange) }}
                  </span>
                  <span v-else>Autocalculated</span>
                </template>
                <span v-else>Calculating....</span>
              </div>
            </div>
          </template>
          <template>
            <div class="w-full flex flex-col justify-start gap-2">
              <p class="text-sm text-jet">Annual Range Maximum (₦)</p>
              <div class="payInfo payInfo_fakeInput text-center text-darkPurple">
                <template v-if="!isFetchingPayGrade">
                  <span v-if="payload.maximumRange">
                    {{ convertToCurrency(payload.maximumRange) }}
                  </span>
                  <span v-else>Autocalculated</span>
                </template>
                <span v-else>Calculating....</span>
              </div>
            </div>
          </template>
        </div>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
  import * as _ from "lodash"
  import VSelect from "vue-select"
  import "vue-select/dist/vue-select.css";
  import { ValidationObserver } from "vee-validate";
  import Select from "@scelloo/cloudenly-ui/src/components/select";
  import TextInput from "@scelloo/cloudenly-ui/src/components/text";
  import RightSideBar from "@/components/RightSideBar";

  export default {
    components: {
      ValidationObserver,
      Select,
      VSelect,
      TextInput,
      RightSideBar,
    },
    data(){
      return {
        isOpen: false,
        isLoading: true,
        isFetchingPayGrade: false,
        levelOptions: [],
        locationOptions: [{
          name: 'All Locations',
          id: 'all_locations'
        }],
        funtionOptions: [],
        payload: {
          payGradeId: '',
          levelId: '',
          locationIds: [],
          functionIds: [],
          designationIds: [],
          currentMarketRate: '',
          policyPercent: '',
          targetPercent: '',
          rangeMidPoint: '',
          midPoint: '',
          rangeSpread: '',
          rangeMinimum: '',
          rangeMaximum: '',
          minimumrange: '',
          maximumRange: '',
          referenceMarketRate: null,
          applyToAllLocation: false,
          applyToAllFunction: false,
          applyToAllDesignation: false,
        }
      }
    },
    computed: {
      designationOptions(){
        const filtered = _.find(this.funtionOptions,
          $el => $el.id === this.payload.functionIds[0]
        )

        if (!filtered) return []
        if(!this.payload.levelId) return filtered.designations
        return  _.filter(filtered.designations,
          $el => $el.level.id === this.payload.levelId
        )
      }
    },
    methods: {
      onToggle(value){
        this.isLoading = true
        this.isOpen = true
        this.payload.referenceMarketRate = value
        this.isLoading = false
      },
      onClose(){
        this.isOpen = false
        this.isLoading = false
        this.payload.maximumRange = ''
        this.payload.minimumrange = ''
        _.assign(this.$data.payload, this.$options.data().payload)

      },
      onReferenceMarketRate(){
        if(this.payload.referenceMarketRate) {
          this.payload.currentMarketRate = Math.abs(this.payload.currentMarketRate)
          if(this.payload.levelId && this.payload.policyPercent && this.payload.currentMarketRate){
            this.isFetchingPayGrade = true
            const payload = {
              levelId: this.payload.levelId,
              policyPercent: this.payload.policyPercent,
              referenceMarketRate: this.payload.referenceMarketRate,
              currentMarketRate: this.payload.currentMarketRate
            }
            this.$_getReferenceMarketRate(payload).then(({ data }) => {
              this.payload = { ...this.payload, ...data.data }
              this.isFetchingPayGrade = false
            })
          }
        } else {
          this.payload.midPoint = Math.abs(this.payload.midPoint)
          if(this.payload.levelId && this.payload.midPoint){
            this.isFetchingPayGrade = true
            const payload = {
              levelId: this.payload.levelId,
              midPoint: this.payload.midPoint,
              referenceMarketRate: this.payload.referenceMarketRate,
            }
            this.$_getNotReferenceMarketRate(payload).then(({ data }) => {
              this.payload = { ...this.payload, ...data.data }
              this.isFetchingPayGrade = false
            })
          }
        }
      },
      onSubmit() {
        this.isLoading = true
        if(_.includes(this.payload.locationIds, 'all_locations')){
          this.payload.applyToAllLocation = true
          this.payload.locationIds = []
        }

        const payload = {
          ...this.payload,
          orgId: this.$orgId,
          payGradeName: this.payload.payGradeId,
        }

        this.$_postOrgPayGrades(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.$emit('success', data)
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      },
      async getLocation() {
        await this.$_getHumanarLocations().then(({ data }) => {
          this.locationOptions.push(...data.outlets.map(item => ({
            value: item.id, ...item
          })))
        });
      },
      async getFunctionDesignations() {
        await this.$_getFunctionDesignations().then(({ data }) => {
          this.funtionOptions = data.functions.map((item) => ({
            value: item.id, ...item
          }))
        })
      },
      async getLevels() {
        await this.$_getLevels().then(({ data }) => {
          this.levelOptions = data.levels.map(item => ({
            value: item.id, ...item
          }))
        })
      },
    },
    async created(){
      await this.getLocation()
      await this.getFunctionDesignations()
      await this.getLevels()
      this.isLoading = false
    },
  };
</script>

<style lang="scss">
.rightSidebar{
  width: 540px !important;
}
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    height: 40px;
  }
}
</style>
